import Axios from "axios";
import constant from "../constant";
import api from "../api";

export default {
  GET_CATEGORIES: (query) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.Category.GET_CATEGORIES(query),
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  ADD_CATEGORIES: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.Category.ADD_CATEGORIES(),
          data,
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  GLOBAL_JSON_SYNC: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.Category.GLOBAL_JSON_SYNC(),
          data,
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  UPDATE_CATEGORIES: (id, updatedData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(
          constant.BASE_URL + api.Category.UPDATE_CATEGORIES(id),
          updatedData,
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  DELETE_CATEGORIES: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.delete(
          constant.BASE_URL + api.Category.DELETE_CATEGORIES(id),
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
};
