/**
 * @file Geocode.js
 * @description Geocode helper to get address from lat and lng
 * @import Geocode from "react-geocode";
 * @import { axiosConstant } from "./Axios/Constants";
 * @param {number} lat
 * @param {number} lng
 * @returns {string} address - address from lat and lng
 */

import Geocode from "react-geocode";
import { GOOGLE_GEOCODE_API_KEY } from "./ENUM";

Geocode.setApiKey(GOOGLE_GEOCODE_API_KEY);

export const geocode = async (lat, lng) => {
  const response = await Geocode.fromLatLng(lat, lng);
  const address = response.results[0].formatted_address;
  return address;
};
