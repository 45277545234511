import Axios from "axios";
import constant from "../constant";
import api from "../api";

export default {
  GET_ALL_BUSINESS_TYPE: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.BusinessType.GET_ALL_BUSINESS_TYPE(),
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
            },
          }
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  ADD_BUSINESS_TYPE: (data) => {


    console.log('apidata',data.getAll('image'));

    return new Promise(async (resolve, reject) => {      
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.BusinessType.ADD_BUSINESS_TYPE(),
          data,
          {
            headers: {
              //"Content-Type": "application/json",
              "x-api-key": constant.X_API_KEY,
            },
          }
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  GET_ALL_BUSINESS_TYPE_BY_QUERY: (query) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL +
            api.BusinessType.GET_ALL_BUSINESS_TYPE_BY_QUERY(query),
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
            },
          }
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  UPDATE_BUSINESS_TYPE: (id, updatedData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(
          constant.BASE_URL + api.BusinessType.UPDATE_BUSINESS_TYPE(id),
          updatedData,
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
            },
          }
        );

        resolve(response);
      } catch (error) {
        reject(error, "errror in update");
      }
    });
  },
  GET_BUSINESS_TYPE_BY_ID: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.BusinessType.GET_BUSINESS_TYPE_BY_ID(id),
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error, "errror in get by id business type");
      }
    });
  },
  DELETE_BUSINESS_TYPE: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.delete(
          constant.BASE_URL + api.BusinessType.DELETE_BUSINESS_TYPE(id),
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error, "errror in get by id business type");
      }
    });
  }
};
