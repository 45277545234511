import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Grid, Button, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import services from "../../../services";
import modalClose from "../../../assets/images/structure/close-modal.png";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
import { useDispatch } from "react-redux";
import "./header.scss";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const routerLogin = () => {
    localStorage.clear();
    const path = `/`;
    navigate(path);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [showPasswordOld, setShowPasswordOld] = React.useState(false);
  const ShowPasswordOld = () => setShowPasswordOld((show) => !show);
  const handleMouseDownPasswordOld = (event) => {
    event.preventDefault();
  };

  const [showPasswordNew, setShowPasswordNew] = React.useState(false);
  const ShowPasswordNew = () => setShowPasswordNew((show) => !show);
  const handleMouseDownPasswordNew = (event) => {
    event.preventDefault();
  };

  const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false);
  const ShowPasswordConfirm = () => setShowPasswordConfirm((show) => !show);
  const handleMouseDownPasswordConfirm = (event) => {
    event.preventDefault();
  };
  const [openChangePassword, setOpenChangePassword] = React.useState(false);
  const OpenChangePassword = () => {
    setOpenChangePassword(true);
  };
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [conformpassword, setConformPassword] = useState("");
  const [oldPasswordError, setOldPasswordError] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [conformpasswordError, setConformPasswordError] = useState(false);
  const [oldPasswordErrorText, setOldPasswordErrorText] = useState("");
  const [newPasswordErrorText, setNewPasswordErrorText] = useState("");
  const [conformpasswordErrorText, setConformPasswordErrorText] = useState("");

  const handleCloseChangePassword = () => {
    setOpenChangePassword(false);
  };

  const changePassword = async () => {
    if (validatePassword()) {
      const data = {
        old_password: oldPassword,
        new_password: newPassword,
        confirm_password: conformpassword,
      };
      const response = await services.auth.CHANGE_PASSWORD(data);
      if (response?.data?.ack == 1) {
        handleCloseChangePassword();
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: response?.data?.msg,
            state: "success",
          })
        );
      }
    }
  };
  const validatePassword = () => {
    if (!oldPassword) {
      setOldPasswordError(true);
      setOldPasswordErrorText("Please enter old password");
    }
    if (!newPassword) {
      setNewPasswordError(true);
      setNewPasswordErrorText("Please enter new password");
    }
    if (!conformpassword) {
      setConformPasswordError(true);
      setConformPasswordErrorText("Please enter conform password");
    }
    if (oldPassword && newPassword && conformpassword) {
      if (newPassword !== conformpassword) {
        setConformPasswordError(true);
        setConformPasswordErrorText("Password not match");
      }
      if (newPassword === conformpassword) {
        if (newPassword.length < 8) {
          setNewPasswordError(true);
          setNewPasswordErrorText("Password must be 8 character long");
        } else return true;
      }
    }
    return false;
  };
  return (
    <>
      <div className="header-wrapper">
        <Box display={"flex"} justifyContent={"flex-end"}>
          <Box
            sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
          >
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <Avatar sx={{ width: 32, height: 32 }}>S</Avatar>
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {/* <MenuItem>Profile</MenuItem> */}
            {/* <MenuItem>
                             My account
                        </MenuItem> */}
            <MenuItem onClick={OpenChangePassword}>Change Password</MenuItem>
            <Divider />
            <MenuItem onClick={routerLogin}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </Box>
      </div>

      <Dialog open={openChangePassword} onClose={handleCloseChangePassword}>
        <img
          src={modalClose}
          alt="..."
          onClick={handleCloseChangePassword}
          className="modal-close"
        />
        <DialogContent>
          <Typography variant="h6" component="h6" mb={3}>
            Change Password
          </Typography>

          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl variant="outlined">
                  <InputLabel htmlFor="baladiPasswordOld">
                    Old Password
                  </InputLabel>
                  <OutlinedInput
                    id="baladiPasswordOld"
                    type={showPasswordOld ? "text" : "password"}
                    onChange={(e) => {
                      setOldPassword(e.target.value);
                      setOldPasswordError(false);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={ShowPasswordOld}
                          onMouseDown={handleMouseDownPasswordOld}
                          edge="end"
                        >
                          {!showPasswordOld ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Old Password"
                  />
                  {oldPasswordError && (
                    <span style={{ color: "red" }}>{oldPasswordErrorText}</span>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl variant="outlined">
                  <InputLabel htmlFor="baladiPasswordNew">
                    New Password
                  </InputLabel>
                  <OutlinedInput
                    id="baladiPasswordNew"
                    type={showPasswordNew ? "text" : "password"}
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                      setNewPasswordError(false);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={ShowPasswordNew}
                          onMouseDown={handleMouseDownPasswordNew}
                          edge="end"
                        >
                          {!showPasswordNew ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="New Password"
                  />
                  {newPasswordError && (
                    <span style={{ color: "red" }}>{newPasswordErrorText}</span>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl variant="outlined">
                  <InputLabel htmlFor="baladiPasswordConfirm">
                    Confirm Password
                  </InputLabel>
                  <OutlinedInput
                    id="baladiPasswordConfirm"
                    type={showPasswordConfirm ? "text" : "password"}
                    onChange={(e) => {
                      setConformPassword(e.target.value);
                      setConformPasswordError(false);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={ShowPasswordConfirm}
                          onMouseDown={handleMouseDownPasswordConfirm}
                          edge="end"
                        >
                          {!showPasswordConfirm ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Confirm Password"
                  />
                  {conformpasswordError && (
                    <span style={{ color: "red" }}>
                      {conformpasswordErrorText}
                    </span>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Button
                  onClick={changePassword}
                  variant="contained"
                  size="large"
                  sx={{ width: 1, marginTop: "16px" }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Header;
