export const GOOGLE_GEOCODE_API_KEY = "AIzaSyAAB45EPGbb-F1CAULCyQxZIMvDdGJH8aA-(commented)";
export const API_GET_GEOLOCATION = `https://services.gisqatar.org.qa/server/rest/services/Vector/QARS1/MapServer/0/query`;
export const VALIDATE_COORDINATES = `https://epsg.io/trans`;
export const X_API_KEY = "baladi_dev_KHYUIS7L3xa07dsfgdyWA8dMRRsFkc1sfd3432jLB";

export const HEADERS = {
  "x-api-key": X_API_KEY,
};

export const MONTH = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const API = {
  ADD_LOCATION : `/location/add`,
  GET_LOCATION : `/location/get`,
  UPDATE_LOCATION : `/location/update`,
  GET_LOCATION_BY_ID : (locationId) => `/location/get-location-by-id/${locationId}`,
  DELETE_LOCATION : '/location/delete',
  ADD_ASSOCIATES : '/stores/add-associates',
  GET_ASSOCIATES : '/stores/get-associates'
}
