import Auth from "./auth";
import BusinessType from "./BusinessType";
import Category from "./Category";
import Stores from "./Stores";
import Products from "./Products";
import Addons from "./Addons";
import Orders from "./Orders";
import Drivers from "./DriverList";
import Vendors from "./Vendors";
import Unit from "./Unit";
import systemSetting from "./systemSetting";
import manageCustomerListing from "./manageCustomerListing";
import payoutManagement from "./payoutManagement";
import cms from "./cms";
import manageUser from "./manageUser";
import manageDriver from "./manageDriver";
import faq from "./faq";
import sectionManager from "./sectionManager";
import manageFinancialUser from "./manageFinancialUser";
import Bulk from './Bulk';
export default {
  Auth,
  BusinessType,
  Category,
  Stores,
  Unit,
  Products,
  Drivers,
  systemSetting,
  manageCustomerListing,
  payoutManagement,
  cms,
  Orders,
  manageUser,
  manageDriver,
  faq,
  sectionManager,
  manageFinancialUser,
  Addons,
  Bulk
};
