import { createSlice } from "@reduxjs/toolkit";

export const manageCustomer = createSlice({
  name: "manageCustomer",
  initialState: {
    redirect: null,
  },
  reducers: {
    changeRedirect: (state, action) => {
      state.redirect = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeRedirect } = manageCustomer.actions;
export const currentRedirect = (state) => state.manageCustomer.redirect;
export default manageCustomer.reducer;
