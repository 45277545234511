import styled from 'styled-components/macro'


export const Boxed = styled.div`
  display: ${props => props.display};
  flex: ${props => props.flex};
  align-items: ${props => props.alignItems};
  justify-content: ${props => props.justifyContent};
  flex-wrap: ${props => props.flexWrap};
  flex-direction: ${props => props.flexDirection};
  text-align: ${props => props.textAlign};
  padding: ${props => props.padding};
  padding-top: ${props => props.pt};
  padding-right: ${props => props.pr};
  padding-bottom: ${props => props.pb};
  padding-left: ${props => props.pl};
  margin: ${props => props.margin};
  margin-top: ${props => props.mt};
  margin-right: ${props => props.mr};
  margin-bottom: ${props => props.mb};
  margin-left: ${props => props.ml};
  flex-grow: ${props => props.flexGrow};
  color: ${props => props.color};
  line-height: ${props => props.lineHeight};
  letter-spacing: ${props => props.letterSpacing};
  border-bottom: ${props => props.borderBottom};
  cursor: ${props => props.cursor};
  text-decoration: ${props => props.textDecoration};
  text-transform: ${props => props.textTransform};
  user-select: ${props => props.userSelect};
  text-overflow: ${props => props.ellipsis};
  overflow: ${props => props.hidden};
  width: ${props => props.width};
  className: ${props => props.className}
  &::after {
    content: '';
  }

  ::-webkit-scrollbar {
    display: none;
  }

  @media(max-width: 1000px) {
    padding: ${props => props.mobilePadding};
  }
`;


Boxed.defaultProps = {
  lineHeight: 'normal',
  letterSpacing: 'normal',
}


