export default {
  GET_DRIVERS: (limit, page, sort_by, order_by, search) => {
    return `/drivers/all?limit=${limit}&page=${page}&sort_by=${sort_by}&order_by=${order_by}&search=${search}`;
  },
  GET_SPECIFIC_DRIVER: (id) => `/drivers/detail/${id}`,
  ADD_DRIVER: `/drivers/add`,
  DELETE_DRIVER: (id) => `/drivers/delete/${id}`,
  EDIT_DRIVER: (id) => `/drivers/edit/${id}`,
  GET_TEAM_ID: `/drivers/all-team`,
};
