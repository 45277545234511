import { Box, Typography } from "@mui/material";
import Aside from "../../layout/aside/Aside";
import Header from "../../layout/header/Header";
// import images
export default function Dashboard() {
  return (
    <>
      <Box className="dashboard-parent">
        <Header />
        <Aside />
        <Box className="main-wrapper">
          <Box className="white-box-20-30" sx={{ minHeight: "75vh" }}>
            <Typography variant="h6" component="h6" mb={3} fontWeight={500}>
              Dashboard
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}
