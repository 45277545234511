export default {
  GET_CUSTOMER_LIST: (page, limit, search, order, sort_by,startDate = null,endDate = null) =>
   {
    let str = '';
    if(startDate!== null){
        str += `&startDate=${startDate}`;
    }
    if(endDate !== null){
        str += `&endDate=${endDate}`
    }
    return  `/customers/get-customers-list/?page=${page}&limit=${limit}&sort_by=${sort_by}&search=${search}&order_by=${order}${str}`
   },
  GET_CUSTOMER_DETAILS: (id) =>
    `/customers/get-customers-details/?customer_id=${id}`,
  ADD_MONEY: `/customers/add-money-in-wallet`,
  UPDATE_STATUS: `/customers/customer-status-update`,
  UPDATE_CUSTOMER: `/customers/customer-update`,
};
