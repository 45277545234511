import Axios from "axios";
import constant from "../constant";
import api from "../api";

export default {
  GET_DRIVERS: (limit, page, sort_by, order_by, search) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL +
            api.manageDriver.GET_DRIVERS(
              limit,
              page,
              sort_by,
              order_by,
              search
            ),
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  GET_SPECIFIC_DRIVER: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.manageDriver.GET_SPECIFIC_DRIVER(id),
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  ADD_DRIVER: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.manageDriver.ADD_DRIVER,
          data,
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  DELETE_DRIVER: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.delete(
          constant.BASE_URL + api.manageDriver.DELETE_DRIVER(id),
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  EDIT_DRIVER: (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(
          constant.BASE_URL + api.manageDriver.EDIT_DRIVER(id),
          data,
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  GET_TEAM_ID: (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.manageDriver.GET_TEAM_ID,
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
};
