import Axios from "axios";
import constant from "../constant";
import api from "../api";

export default {
  GET_FAQ: (role, is_admin, limit, page) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.faq.GET_FAQ(role, is_admin, limit, page),
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  ADD_FAQ: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(
          constant.BASE_URL + api.faq.ADD_FAQ,
          data,
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  GET_FAQ_BY_ID: (role, faqId, isAdmin) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.faq.GET_FAQ_BY_ID(role, faqId, isAdmin),
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  UPDATE_FAQ_STATUS: (id, data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(
          constant.BASE_URL + api.faq.UPDATE_FAQ_STATUS(id),
          data,
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  DELETE_FAQ: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.delete(
          constant.BASE_URL + api.faq.DELETE_FAQ(id),
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
};
