import Axios from "axios";
import constant from "../constant";
import api from "../api";

export default {
  ADD_BANNER: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.systemSetting.ADD_BANNER,
          data,
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  GET_ALL_BANNER: (query, page, limit) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL +
            api.systemSetting.GET_ALL_BANNER(query, page, limit),
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  GET_BUSINESSTYPE: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.systemSetting.GET_BUSINESSTYPE,
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  UPDATE_BANNER: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(
          constant.BASE_URL + api.systemSetting.UPDATE_BANNER,
          data,
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  UPDATE_BANNER_STATUS: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.put(
          constant.BASE_URL + api.systemSetting.UPDATE_BANNER_STATUS,
          data,
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  DELETE_BANNER: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.delete(
          constant.BASE_URL + api.systemSetting.DELETE_BANNER(id),
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  GLOBAL_JSON_SYNC: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.systemSetting.GLOBAL_JSON_SYNC,
          data,
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  GET_ALL_USERS: (page, limit, sort_by, order_by, created_by, search, role) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL +
            api.manageFinancialUser.GET_ALL_USERS(
              page,
              limit,
              sort_by,
              order_by,
              created_by,
              search,
              role
            ),
          {
            headers: {
              "x-api-key": constant.X_API_KEY,
            },
          }
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
};
